const calculateOrderSummary = order => {
  const orderItems = order.records
  const selectedOrderItems = orderItems.filter(orderItem => orderItem.selected)

  const totalItems = orderItems.length
  const zeroStockItems = orderItems.filter(orderItem => orderItem.is_zero_stock).length
  const lessThanXDaysItems = orderItems.filter(orderItem => orderItem.is_less_than_x_days).length
  const hashItems = orderItems.filter(orderItem => orderItem.is_hash_item).length
  const selectedItems = orderItems.filter(orderItem => orderItem.selected).length
  const reorderValueCeil = +selectedOrderItems.reduce((sum, orderItem) => sum + orderItem.reorder_value_ceil, 0).toFixed(2)
  const reorderValueFloor = +selectedOrderItems.reduce((sum, orderItem) => sum + orderItem.reorder_value_floor, 0).toFixed(2)
  const reorderValueRound = +selectedOrderItems.reduce((sum, orderItem) => sum + orderItem.reorder_value_round, 0).toFixed(2)
  const reorderValue = +selectedOrderItems.reduce((sum, orderItem) => sum + orderItem.reorder_value, 0).toFixed(2)

  return {
    ...order.summary,
    total_items: totalItems,
    zero_stock_items: zeroStockItems,
    less_than_x_days_items: lessThanXDaysItems,
    hash_items: hashItems,
    selected_items: selectedItems,
    reorder_value_ceil: reorderValueCeil,
    reorder_value_floor: reorderValueFloor,
    reorder_value_round: reorderValueRound,
    reorder_value: reorderValue,
  }
}

export default {
  namespaced: true,
  state: {
    orders: [],
    editOrderId: null,
  },
  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_EDIT_ORDER_ID(state, index) {
      state.editOrderId = index
    },
    UPDATE_ORDER(state, order) {
      const orderIndex = state.orders.findIndex(item => item.company_group_id === order.company_group_id)
      state.orders.splice(orderIndex, 1, order)
    },
    DELETE_ORDER(state, order) {
      const orderIndex = state.orders.findIndex(item => item.company_group_id === order.company_group_id)
      state.orders.splice(orderIndex, 1)
    },
  },
  actions: {
    reset({ commit }) {
      commit('SET_ORDERS', [])
      commit('SET_EDIT_ORDER_ID', null)
    },
  },
  getters: {
    orders(state) {
      return state.orders.map(order => ({
        ...order,
        summary: calculateOrderSummary(order),
      }))
    },
    editOrderId(state) {
      return state.editOrderId
    },
  },
}
