import { $themeBreakpoints } from '@themeConfig'

import navMenuItems from '@/navigation/horizontal'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    horizontalNavMenusItems(state, getters, rootState, rootGetters) {
      return navMenuItems.filter(navMenuItem => rootGetters['auth/canAccessGroup'](navMenuItem.group))
    },
    verticalNavMenuItems(state, getters) {
      let menuItems = []
      getters.horizontalNavMenusItems.forEach(menu => {
        menuItems.push({
          header: menu.header,
        })
        menuItems = menuItems.concat(menu.children)
      })
      return menuItems
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
