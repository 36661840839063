import store from '../store'

export default function defaultRoute(to, from, next) {
  const canAccessGroup = store.getters['auth/canAccessGroup']
  if (canAccessGroup('orders')) {
    next({ name: 'purchase-orders' })
  } else if (canAccessGroup('accounts')) {
    next({ name: 'dashboard' })
  } else if (canAccessGroup('sales')) {
    next({ name: 'sales-schedule' })
  } else if (canAccessGroup('returns')) {
    next({ name: 'supplier-return' })
  } else if (canAccessGroup('invoices')) {
    next({ name: 'bilty' })
  } else {
    next()
  }
}
