export default {
  namespaced: true,
  state: {
    token: null,
    userName: null,
    isSuperUser: false,
    groups: [],
  },
  mutations: {
    SET_AUTH_DATA(state, authData) {
      state.token = authData.token
      state.userName = authData.userName
      state.isSuperUser = authData.isSuperUser
      state.groups = authData.groups
    },
  },
  actions: {
    setAuthData({ commit }, authData) {
      commit('SET_AUTH_DATA', authData)
    },
    clearAuthData({ commit }) {
      commit('SET_AUTH_DATA', {
        token: null,
        userName: null,
        isSuperUser: false,
        groups: [],
      })
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token
    },
    userName(state) {
      return state.userName
    },
    token(state) {
      return state.token
    },
    isSuperUser(state) {
      return state.isSuperUser
    },
    groups(state) {
      return state.groups
    },
    canAccessGroup(state) {
      return groupName => {
        if (state.isSuperUser) {
          return true
        }

        if (state.groups.includes(groupName)) {
          return true
        }

        return false
      }
    },
  },
}
