/* eslint-disable radix */
import { extend } from 'vee-validate'

import {
  max as rule_max,
} from 'vee-validate/dist/rules'

extend('matchInvoiceAndPhysicalQuantity', {
  message: 'Invoice quantity should match with sum of physical quantity',
  validate: value => {
    const invoiceQuantity = parseInt(value.invoiceQuantity)
    let totalPhysicalQuantity = 0

    if (Number.isNaN(invoiceQuantity)) {
      return false
    }

    value.receivedItems.forEach(item => {
      const physcialQuantity = parseInt(item.physicalQuantity)
      if (!Number.isNaN(physcialQuantity)) {
        totalPhysicalQuantity += physcialQuantity
      }
    })

    return invoiceQuantity === totalPhysicalQuantity
  },
})

extend('shouldHaveAtleastOneReceivedItem', {
  message: '{_field_} should have atleast one received item',
  validate: value => value.receivedItems?.length > 0,
})

extend('shouldHaveAtleastOneSubItem', {
  message: '{_field_} should have atleast one SubDiscrepancy',
  validate: value => value.items?.length > 0,
})

extend('anyOnePairRequired', {
  message: 'Old and new values for any of Batch, MRP or Expiry are required',
  validate: value => {
    if (value.oldBatch && value.newBatch) {
      return true
    }

    if (value.oldMrp && value.newMrp) {
      return true
    }

    if (value.oldExpiry && value.newExpiry) {
      return true
    }

    return false
  },
})

extend('lessThan', {
  message: '{_field_} should be less than the {target}',
  params: ['target'],
  validate: (value, { target }) => {
    const fieldValue = parseInt(value)
    const otherFieldValue = parseInt(target)

    if (Number.isNaN(otherFieldValue)) {
      return true
    }

    return fieldValue < otherFieldValue
  },
})

extend('greatherThan', {
  message: '{_field_} should be greater than the {target}',
  params: ['target'],
  validate: (value, { target }) => {
    const fieldValue = parseInt(value)
    const otherFieldValue = parseInt(target)

    if (Number.isNaN(otherFieldValue)) {
      return true
    }

    return fieldValue > otherFieldValue
  },
})

// eslint-disable-next-line import/prefer-default-export
export const max = extend('max', rule_max)
