import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import getEnv from '@/utils/env'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import order from './order'
import operations from './operations'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: `${getEnv('VUE_APP_LOCAL_STORAGE_PREFIX')}vuex`,
      paths: ['auth', 'appConfig', 'verticalMenu'],
    }),
  ],
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    order,
    operations,
  },
  strict: process.env.DEV,
})
