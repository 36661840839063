export default {
  namespaced: true,
  state: {
    selectedRackGroups: [],
  },
  mutations: {
    SELECT_RACK_GROUPS(state, ids) {
      ids.forEach(id => {
        if (!state.selectedRackGroups.includes(id)) {
          state.selectedRackGroups.push(id)
        }
      })
    },
    UNSELECT_RACK_GROUPS(state, ids) {
      ids.forEach(id => {
        const index = state.selectedRackGroups.indexOf(id)
        if (index > -1) {
          state.selectedRackGroups.splice(index, 1)
        }
      })
    },
    CLEAR_RACK_GROUP_SELECTION(state) {
      state.selectedRackGroups = []
    },
  },
  actions: {
    selectRackGroups(context, payload) {
      context.commit('SELECT_RACK_GROUPS', payload.ids)
    },
    unSelectRackGroups(context, payload) {
      context.commit('UNSELECT_RACK_GROUPS', payload.ids)
    },
    clearRackGroupSelection(context) {
      context.commit('CLEAR_RACK_GROUP_SELECTION')
    },
  },
  getters: {
    selectedRackGroups(state) {
      return state.selectedRackGroups
    },
  },
}
