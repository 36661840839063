export default [
  {
    header: 'Orders',
    icon: 'PackageIcon',
    group: 'orders',
    children: [
      {
        title: 'Order Uploads',
        route: 'orders-uploads',
        icon: 'UploadCloudIcon',
      },
      {
        title: 'Create Order',
        route: 'create-order',
        icon: 'PlusIcon',
      },
      {
        title: 'Manual Order',
        route: 'manual-order',
        icon: 'EditIcon',
      },
      {
        title: 'Purchase Orders',
        route: 'purchase-orders',
        icon: 'FileTextIcon',
      },
      {
        title: 'Purchase Order Summary',
        route: 'purchase-order-summary',
        icon: 'ListIcon',
      },
    ],
  },
  {
    header: 'Accounts',
    icon: 'BookIcon',
    group: 'accounts',
    children: [
      {
        title: 'Uploads',
        route: 'uploads',
        icon: 'UploadCloudIcon',
      },
      {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'SunIcon',
      },
      {
        title: 'Statements',
        route: 'statements',
        icon: 'GridIcon',
      },
      {
        title: 'Print Statements',
        route: 'print-statements',
        icon: 'PrinterIcon',
      },
      {
        title: 'Credit/Debit Notes',
        route: 'credit-debit-notes',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Default Parties',
        route: 'defualt-parties',
        icon: 'StopCircleIcon',
      },
      {
        title: 'Party Ledger',
        route: 'party-ledger',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Cheques',
        route: 'cheques',
        icon: 'FileIcon',
      },
      {
        title: 'Outstanding Report',
        route: 'outstanding-report',
        icon: 'ListIcon',
      },
      {
        title: 'Sales Targets',
        route: 'sales-targets',
        icon: 'TrendingUpIcon',
      },
    ],
  },
  {
    header: 'Sales',
    icon: 'ActivityIcon',
    group: 'sales',
    children: [
      {
        title: 'Sales Schedule',
        route: 'sales-schedule',
        icon: 'PhoneCallIcon',
      },
      {
        title: "Today's Bills",
        route: 'todays-bills',
        icon: 'FileTextIcon',
      },
      {
        title: 'Orders ',
        route: 'orders',
        icon: 'PackageIcon',
      },
      {
        title: 'Monthly Targets',
        route: 'monthly-targets',
        icon: 'TrendingUpIcon',
      },
    ],
  },
  {
    header: 'Returns',
    icon: 'ArrowDownCircleIcon',
    group: 'returns',
    children: [
      {
        title: 'Supplier Return',
        route: 'supplier-return',
        icon: 'ArrowDownIcon',
      },
      {
        title: 'Supplier Return Summary',
        route: 'supplier-return-summary',
        icon: 'ListIcon',
      },
      {
        title: 'Buyer Return',
        route: 'buyer-return',
        icon: 'ArrowDownIcon', // <-- icon
      },
    ],
  },
  {
    header: 'Invoices',
    icon: 'BookIcon',
    group: 'invoices',
    children: [
      {
        title: 'Bilty ',
        route: 'bilty',
        icon: 'GridIcon',
      },
      {
        title: 'Invoices ',
        route: 'invoices',
        icon: 'ListIcon',
      },
    ],
  },
  {
    header: 'Tickets',
    icon: 'ListIcon',
    group: 'tickets',
    children: [
      {
        title: 'Supplier Tickets ',
        route: 'supplier-tickets',
        icon: 'ClipboardIcon',
      },
      {
        title: 'Buyer Tickets ',
        route: 'buyer-tickets',
        icon: 'ClipboardIcon',
      },
    ],
  },
  {
    header: 'Stocks',
    icon: 'LayersIcon',
    group: 'stocks',
    children: [
      {
        title: 'Purchase Items Stock',
        route: 'purchase-items-stock',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Verified Stock Reports',
        route: 'verified-stock-reports',
        icon: 'CheckSquareIcon',
      },
    ],
  },
  {
    header: 'Delivery',
    icon: 'TruckIcon',
    group: 'delivery_admin',
    children: [
      {
        title: 'Access Management',
        route: 'delivery-access-management',
        icon: 'ToolIcon',
      },
      {
        title: 'Delivery Status',
        route: 'delivery-status',
        icon: 'UserCheckIcon',
      },
      {
        title: 'Delivery Dashboard',
        route: 'delivery-dashboard',
        icon: 'SunIcon',
      },
      {
        title: 'Bus Master',
        route: 'bus-master',
        icon: 'ListIcon',
      },
    ],
  },
  {
    header: 'Operations',
    icon: 'TrelloIcon',
    group: 'operations',
    children: [
      {
        title: 'RackGroup Reports',
        route: 'rack-group-reports',
        icon: 'FileTextIcon',
      },
      {
        title: 'Verify Order Items',
        route: 'verify-order-items',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Packing',
        route: 'packing',
        icon: 'PackageIcon',
      },
    ],
  },
]
