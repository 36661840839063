import store from '../store'

export default function groupAccess(groupName) {
  return (to, from, next) => {
    const canAccessGroup = store.getters['auth/canAccessGroup']
    if (canAccessGroup(groupName)) {
      next()
    } else {
      next({ name: 'error-403' })
    }
  }
}
